<template>
  <div class="wrapper">
    <div class="wrapper__card">
      <div class="wrapper__card__list">
        <div class="wrapper__card__list__item">
          <CardInfo
            :info="connectionCard"
            :permissions="permissions"
            :loggedIn="loggedIn"
          />
        </div>

        <div class="wrapper__card__list__item">
          <CardInfo
            :info="audienceCard"
            :permissions="permissions"
            :loggedIn="loggedIn"
          />
        </div>

        <div class="wrapper__card__list__item">
          <CardInfo
            :info="contentCard"
            :permissions="permissions"
            :loggedIn="loggedIn"
          />
        </div>

        <div class="wrapper__card__list__item">
          <CardInfo
            :info="journeyCard"
            :permissions="permissions"
            :loggedIn="loggedIn"
          />
          <CardInfo
            :info="reportCard"
            :permissions="permissions"
            :loggedIn="loggedIn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardInfo from "./components/cardInfo.vue";
import {
  connectionCard,
  audienceCard,
  contentCard,
  journeyCard,
  reportCard,
} from "./mock";
export default {
  components: {
    CardInfo,
  },
  data() {
    return {
      connectionCard,
      audienceCard,
      contentCard,
      journeyCard,
      reportCard,
      user: null,
      permissions: [],
      loggedIn: null,
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("zooxeye-user"));
    if (this.user && this.user.permissions)
      this.permissions = this.user.permissions;
    this.loggedIn = JSON.parse(localStorage.getItem("loggedIn"));
    console.log(this.user);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.wrapper {
  // padding: $z-s-1 $z-s-2;
  padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    background: #ffffff;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;

    @include trackScrollBar;

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: $z-s-1;
      // border: 3px solid blue;
      min-height: 100%;
      max-height: 100%;

      &__item {
        flex: 1 1 230px;
        display: flex;
        // border: 1px solid red;
        flex-direction: column;
        gap: 1rem;
        max-height: 100%;
      }
    }
  }
  @media (max-width: 750px) {
    padding: $z-s-1;
  }
}
</style>
