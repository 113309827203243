var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-info" }, [
    _c("div", { staticClass: "card-info__header" }, [
      _c("figure", [
        _c("img", {
          attrs: {
            src: _vm.info.image,
            loading: "lazy",
            "cache-control": "max-age=3600",
          },
        }),
      ]),
      _c("span", [_vm._v(" " + _vm._s(_vm.info.title) + " ")]),
      _c("p", [_vm._v(" " + _vm._s(_vm.info.description) + " ")]),
    ]),
    _c("div", { staticClass: "card-info__body" }, [
      _c(
        "div",
        { staticClass: "card-info__body__list" },
        _vm._l(_vm.info.links, function (item, key) {
          return _c(
            "div",
            { key: key, staticClass: "card-info__body__list__item" },
            [
              item.children.length &&
              (_vm.permissions.find((x) => x === item.permission) ||
                _vm.loggedIn)
                ? _c("div", { staticClass: "main" }, [
                    _c("div", { staticClass: "main__header" }, [
                      _c("figure", [
                        _c("img", {
                          attrs: {
                            src: _vm.blueArrowIconPath,
                            "cache-control": "max-age=3600",
                          },
                        }),
                      ]),
                      _c("span", [_vm._v(_vm._s(item.title))]),
                    ]),
                    item.children
                      ? _c(
                          "ul",
                          { staticClass: "main__list" },
                          _vm._l(item.children, function (child, keyChild) {
                            return _c(
                              "li",
                              {
                                key: keyChild,
                                staticClass: "main__list__item",
                              },
                              [
                                _vm.permissions.find(
                                  (x) => x === child.permission
                                ) || _vm.loggedIn
                                  ? _c(
                                      "router-link",
                                      { attrs: { to: child.to } },
                                      [
                                        _c("figure", [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.yellowArrowIconPath,
                                              "cache-control": "max-age=3600",
                                            },
                                          }),
                                        ]),
                                        _vm._v(" " + _vm._s(child.title) + " "),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ])
                : _vm.permissions.find((x) => x === item.permission) ||
                  _vm.loggedIn
                ? _c("div", { staticClass: "main-no-child" }, [
                    _c(
                      "div",
                      { staticClass: "main-no-child__header" },
                      [
                        _c("router-link", { attrs: { to: item.to } }, [
                          _c("figure", [
                            _c("img", {
                              attrs: {
                                src: _vm.blueArrowIconPath,
                                "cache-control": "max-age=3600",
                              },
                            }),
                          ]),
                          _vm._v(" " + _vm._s(item.title) + " "),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }