var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "wrapper__card" }, [
      _c("div", { staticClass: "wrapper__card__list" }, [
        _c(
          "div",
          { staticClass: "wrapper__card__list__item" },
          [
            _c("CardInfo", {
              attrs: {
                info: _vm.connectionCard,
                permissions: _vm.permissions,
                loggedIn: _vm.loggedIn,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wrapper__card__list__item" },
          [
            _c("CardInfo", {
              attrs: {
                info: _vm.audienceCard,
                permissions: _vm.permissions,
                loggedIn: _vm.loggedIn,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wrapper__card__list__item" },
          [
            _c("CardInfo", {
              attrs: {
                info: _vm.contentCard,
                permissions: _vm.permissions,
                loggedIn: _vm.loggedIn,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "wrapper__card__list__item" },
          [
            _c("CardInfo", {
              attrs: {
                info: _vm.journeyCard,
                permissions: _vm.permissions,
                loggedIn: _vm.loggedIn,
              },
            }),
            _c("CardInfo", {
              attrs: {
                info: _vm.reportCard,
                permissions: _vm.permissions,
                loggedIn: _vm.loggedIn,
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }